.menu-wrapper {
  height: 0;
  width: 100%;
}
.menu-space {
  height: 5.4em;
  width: 100%;
  margin-bottom: 3em;
}
.menu {
  z-index: 10;
  width: 100%;
  position: absolute;
  top: 0;
  transition: all 0.25s linear;
  backdrop-filter: blur(12px);
}
.menu-shadow {
  box-shadow: var(--neu-shadow1);
  transition: all 0.2s ease-in-out;
}
.menu::after {
  content: "";
  display: table;
  clear: both;
}
.menu nav {
  float: right;
  padding: 2em 0;
}
.menu nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu nav li {
  display: inline-block;
  margin-left: 3em;
  font-size: 1em;
  position: relative;
}
.menu nav a {
  font-size: 1em;
  color: var(--black-text);
  text-decoration: none;
  text-transform: uppercase;
}
body.dark .menu nav a {
  color: var(--white-text);
}
.menu nav a:hover,
body.dark .menu nav a:hover {
  color: var(--color-primary);
}
.menu nav a::after {
  content: "";
  display: block;
  height: 3px;
  background-color: var(--color-primary);
  width: 0;
  position: absolute;
  transition: all ease-in-out 250ms;
}
.menu nav a:hover::after {
  width: 100%;
}
.menu .logo {
  float: left;
  width: 64px;
  height: 64px;
  padding: 0.66em 0;
  cursor: pointer;
}
.menu .logo .logo-segment {
  stroke: var(--black-text);
  fill: none;
  stroke-miterlimit: 10;
  stroke-width: 35px;
}
body.dark .menu .logo .logo-segment {
  stroke: var(--white-text);
}
.menu .lang,
.menu .theme-preview {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  border: solid #fff 2px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.menu [lang="DE"] {
  background-image: url("/public/img/de_icon.svg");
}
.menu [lang="EN"] {
  background-image: url("/public/img/en_icon.svg");
}
.menu .lang-change,
.menu .theme-select {
  position: absolute;
  right: 2em;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: var(--neu-shadow2);
}
body.dark .menu .lang-change,
body.dark .menu .theme-select {
  box-shadow: var(--neu-shadow-dark2);
}
.menu .lang-dropdown,
.menu .theme-dropdown {
  position: absolute;
  display: flex;
  right: calc(2em - 6px);
  top: calc(3.5em + 13px);
  opacity: 1;
  transition: opacity 0.1s linear;
  background: var(--light-back);
  border-radius: 5px;
  padding: 0.5em 1em;
  filter: drop-shadow(1px 1px 5px rgb(177, 181, 198));
}
.menu .lang-dropdown::before,
.menu .theme-dropdown::before {
  z-index: -1 !important;
  content: "";
  position: absolute;
  background: var(--light-back);
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
  right: 10px;
  top: -5px;
  opacity: 1;
  transition: opacity 0.1s linear;
}
body.dark .menu .lang-dropdown,
body.dark .menu .lang-dropdown::before,
body.dark .menu .theme-dropdown,
body.dark .menu .theme-dropdown::before {
  background: var(--dark-back);
  filter: drop-shadow(1px 1px 5px rgb(30, 31, 37));
}
.menu .lang-dropdown .lang:first-child {
  margin-right: 0.5em;
}
.menu .lang-dropdown-hidden,
.menu .lang-dropdown-hidden::before,
.menu .theme-dropdown-hidden,
.menu .theme-dropdown-hidden::before {
  position: absolute;
  opacity: 0;
  transition:
    opacity,
    height 0.1s linear;
  pointer-events: none;
}
@media (min-width: 720px) {
  .menu .lang-dropdown-hidden {
    height: 0;
    transition: height 0.1s linear;
  }
}

.menu .theme-select {
  right: 4.25em;
  /* background-image: url('/files/img/sun.svg'); */
}
.menu .theme-preview[theme="dark"] {
  background: #2d3035;
}
.menu .theme-preview[theme="light"] {
  background: #e8e8e8;
}
body.dark .menu .theme-preview[theme="light"] {
  background: #fff;
}
.menu .theme-preview[theme="auto"] {
  background: #fff;
}
.menu .theme-preview::after,
.menu .theme-preview::before {
  content: "";
  position: absolute;
  width: 0.75em;
  height: 1.5em;
  top: 50%;
  left: 50%;
}
.menu .theme-preview[theme="auto"]::after {
  border-radius: 0.75em 0 0 0.75em;
  transform: translate(-100%, -50%);
  background: #e8e8e8;
}
body.dark .menu .theme-preview[theme="auto"]::after {
  background: #fff;
}
.menu .theme-preview[theme="auto"]::before {
  border-radius: 0 0.75em 0.75em 0;
  transform: translate(0, -50%);
  background: #2d3035;
}
.menu .theme-dropdown {
  right: calc(4.5em - 10px);
}
.menu .theme-dropdown .theme-preview {
  margin-left: 0.5em;
}
.menu .theme-dropdown .theme-preview:first-child {
  margin-left: 0;
}

.menu .hamburger-btn {
  position: absolute;
  top: 1.75em;
  right: 2em;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.menu .hamburger-btn .bar {
  fill: var(--black-text);
}
body.dark .menu .hamburger-btn .bar {
  fill: var(--white-text);
}
.menu .hamburger-icon {
  width: 100%;
  height: 100%;
}
.menu .hamburger-icon .bar {
  transform-origin: 50% 50%;
  transition: all 0.25s linear;
}
.menu-expanded .hamburger-icon .bar-1 {
  transform: rotate(45deg) translateY(23px);
  transition: all 0.25s linear;
}
.menu-expanded .hamburger-icon .bar-2 {
  transform: scale(0);
  transition: all 0.25s linear;
}
.menu-expanded .hamburger-icon .bar-3 {
  transform: rotate(-45deg) translateY(-23px);
  transition: all 0.25s linear;
}

@media (max-width: 1400px) and (min-width: 1024px) {
  .menu nav {
    padding-right: 5em;
  }
}
@media (max-width: 1024px) and (min-width: 720px) {
  .menu nav {
    padding-right: 6em;
  }
}
@media (max-width: 720px) {
  .menu-expanded {
    background: var(--light-back);
    box-shadow: 0 5px 10px 3px #00000044;
    transition: all 0.25s linear;
  }
  body.dark .menu-expanded {
    background: var(--dark-back);
    box-shadow: 0 5px 10px 3px #00000066;
  }
  .menu .hamburger-btn {
    display: flex;
  }
  .menu .navbar-links {
    display: none;
    width: 100%;
  }
  .menu-expanded .navbar-links {
    display: block;
  }
  .menu nav {
    z-index: 1;
    width: 100%;
    height: 0;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    transition: all 0.25s linear;
  }
  .menu-expanded nav {
    height: 14em;
  }
  .menu nav ul {
    flex-direction: column;
  }
  .menu nav li {
    padding: 0.5em 0;
    margin: 0;
    display: block;
    text-align: center;
  }
  .menu nav a {
    font-size: 1.25em;
  }
  .menu nav a::after {
    display: none;
  }
  .menu .container {
    width: 100%;
  }
  .menu .logo {
    margin-left: 1em;
  }
  .menu .lang-dropdown,
  .menu .lang-dropdown-hidden,
  .menu .theme-dropdown,
  .menu .theme-dropdown-hidden {
    position: static !important;
    opacity: 1 !important;
    display: flex;
    pointer-events: all;
    align-items: center;
    justify-content: center;
    margin-top: 0.5em;
  }
  .menu .lang-dropdown .lang:first-child,
  .menu .lang-dropdown-hidden .lang:first-child {
    margin-right: 0.75em;
  }
  .menu .theme-dropdown,
  .menu .theme-dropdown-hidden {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .menu .theme-dropdown .theme-preview,
  .menu .theme-dropdown-hidden .theme-preview {
    margin-left: 0.75em;
  }
  .menu .theme-dropdown .theme-preview:first-child,
  .menu .theme-dropdown-hidden .theme-preview:first-child {
    margin-left: 0;
  }
  .menu .lang,
  .menu .theme-preview {
    display: none;
  }
  .menu-expanded .lang-dropdown .lang,
  .menu-expanded .lang-dropdown-hidden .lang,
  .menu-expanded .theme-dropdown .theme-preview,
  .menu-expanded .theme-dropdown-hidden .theme-preview {
    display: block;
  }
}
