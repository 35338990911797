.timeline-wrapper {
  --section-height: 8em;
  --bar-height: 3em;
  position: relative;
}
.timeline-wrapper .timeline {
  display: grid;
  height: calc(var(--section-height) * 2 + var(--bar-height));
}
.timeline-wrapper .timeline-bar {
  z-index: -1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: var(--bar-height);
  width: calc(100% - 2 * 17px);
  margin: 0 17px;
  background: linear-gradient(90deg, rgb(35, 155, 229), rgb(37, 118, 195));
}
.timeline-wrapper .timeline-bar::before {
  content: "";
  position: absolute;
  left: 0;
  background: #239be5;
  width: var(--bar-height);
  height: var(--bar-height);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 50% 50%);
  transform: translateX(-50%);
}
.timeline-wrapper .timeline-section {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 -2em;
}
.timeline-wrapper .timeline-section::after {
  content: "";
  position: absolute;
  width: 2.12em;
  height: 2.12em;
  top: 50%;
  right: 0;
  background: transparent;
  transform: translate(-100%, -50%) rotate(45deg);
  box-shadow: 5px -5px 5px -1px #0003;
}
.timeline-wrapper .timeline-section:last-child::after {
  background: #2576c3;
  transform: translate(0, -50%) rotate(45deg);
}
.timeline-wrapper .timeline-section:first-child {
  margin: 0 -2em 0 0;
}
.timeline-wrapper .timeline-section:last-child {
  margin: 0 0 0 -2em;
}
.timeline-wrapper .timeline-section:nth-child(even),
.timeline-wrapper .timeline-section:nth-child(even) .timeline-section-space {
  flex-direction: column-reverse;
}
.timeline-wrapper .timeline-section-space {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: var(--section-height);
}
.timeline-wrapper .timeline-section-label {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1.2em;
}
.timeline-wrapper .timeline-section-label p {
  margin: 0;
}
.timeline-wrapper .timeline-section-space img {
  height: 3em;
}
.timeline-wrapper .timeline-section-arrow {
  height: var(--bar-height);
  display: flex;
  justify-content: center;
  align-items: center;
}
.timeline-wrapper .timeline-year {
  color: #fff;
}
.timeline-wrapper .timeline-connection {
  width: 2px;
  height: 100%;
  background: #2c2c2caa;
}

@media (max-width: 1024px) and (min-width: 768px) {
  .timeline-wrapper .timeline-section-label {
    flex-direction: column;
  }
  .timeline-wrapper .timeline-section:nth-child(even) .timeline-section-label {
    flex-direction: column-reverse;
  }
  .timeline-wrapper {
    --section-height: 10em;
  }
  .timeline-wrapper .timeline-section-label p {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .timeline-wrapper .timeline {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .timeline-wrapper .timeline-section,
  .timeline-wrapper .timeline-section:first-child,
  .timeline-wrapper .timeline-section:last-child,
  .timeline-wrapper .timeline-section-space,
  .timeline-wrapper .timeline-section:nth-child(even),
  .timeline-wrapper .timeline-section:nth-child(even) .timeline-section-space {
    flex-direction: row-reverse;
    margin: 0;
  }
  .timeline-wrapper .timeline-section-space {
    flex-grow: 1;
    justify-content: start;
  }
  .timeline-wrapper .timeline-section-space:last-child {
    display: none;
  }
  .timeline-wrapper .timeline-section-space div:last-child {
    display: none;
  }
  .timeline-wrapper .timeline-section::after {
    transform: translate(7px, 100%) rotate(135deg);
    right: unset;
    left: 0;
  }
  .timeline-wrapper .timeline-section:last-child::after {
    transform: translate(7px, 48px) rotate(135deg);
  }
  .timeline-wrapper .timeline-section-arrow {
    height: var(--section-height);
    width: var(--bar-height);
  }
  .timeline-wrapper .timeline-bar {
    width: var(--bar-height);
    height: calc(100% - 48px);
    margin: 48px 0 0 0;
    transform: unset;
    left: 0;
    top: 0;
    background: linear-gradient(135deg, rgb(35, 155, 229), rgb(37, 118, 195));
  }
  .timeline-wrapper .timeline-bar::before {
    transform: rotate(90deg) translateX(-100%);
  }
}
@media (max-width: 768px) and (min-width: 400px) {
  .timeline-wrapper .timeline-connection {
    width: 25%;
    height: 2px;
    margin: 0 16px;
  }
}
@media (max-width: 400px) {
  .timeline-wrapper .timeline-connection {
    width: 10%;
    height: 0;
  }
  .timeline-wrapper .timeline-section-label {
    flex-direction: column;
    width: 100%;
  }
  .timeline-wrapper .timeline-section-label p {
    text-align: center;
  }
}
