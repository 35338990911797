.code {
  display: inline-block;
  margin: 0 0 1em;
  padding: 0;
  width: calc(100% - 2em);
}
pre.code-display {
  position: relative;
  border-radius: 8px;
  padding: 1em;
  box-shadow: var(--neu-shadow1);
  background: rgb(244, 247, 254) !important;
  overflow-x: auto;
}
pre.code-display * {
  font-family: "Consolas", "Monaco", "Andale Mono", "Ubuntu Mono", monospace;
}
body.dark pre.code-display {
  box-shadow: var(--neu-shadow-dark1);
  background: linear-gradient(134deg, rgb(39, 40, 45), rgb(51, 54, 61)) !important;
}
pre.code-display span {
  background: #0000 !important;
}
.lang::after {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 15px 5px 15px;
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 0 5px 0 0;
}
.lang.js::after {
  content: "js";
  background: #ebb82b;
}
.lang.csharp::after {
  content: "c#";
  background: #22a7f0;
}
.lang.php::after {
  content: "php";
  background: #b588f4;
}
.lang.cpp::after {
  content: "c++";
  background: #b588f4;
}
.lang.glsl::after {
  content: "glsl";
  background: linear-gradient(
    90deg,
    rgba(223, 220, 104, 1) 0%,
    rgba(106, 224, 95, 1) 25%,
    rgba(90, 208, 209, 1) 50%,
    rgba(88, 88, 207, 1) 75%,
    rgba(207, 92, 210, 1) 100%
  );
}
.lang.python::after {
  content: "py";
  background: #ebb82b;
}
