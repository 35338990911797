.skill-display {
  display: flex;
  align-items: center;
  gap: 8px;
}
.skill-bar {
  display: flex;
  gap: 4px;
}
.skill-bar .skill-fill {
  width: 100%;
  background: #0000;
  border-radius: 0;
  box-shadow: var(--neu-shadow1);
}
.skill-bar .bar-end {
  border-radius: 0 5px 5px 0;
}
.skill-bar .s1 {
  border-radius: 5px 0 0 5px;
  background: linear-gradient(90deg, rgb(35, 155, 229), rgb(37, 118, 195));
  box-shadow: -2px -2px 4px 0 rgb(188, 217, 255), 2px 2px 4px 0 rgb(36, 134, 201),
    1px 1px 7px 0 rgb(235, 236, 240);
}
.skill-bar .s2 {
  border-radius: 0;
  background: linear-gradient(90deg, rgba(131, 95, 166), rgba(87, 66, 117));
  box-shadow: -2px -2px 4px 0 rgb(206, 175, 222), 2px 2px 4px 0 rgb(86, 90, 151),
    1px 1px 7px 0 rgb(235, 236, 240);
}
.skill-bar .s3 {
  border-radius: 0 5px 5px 0;
  background: linear-gradient(90deg, rgba(231, 35, 111), rgba(133, 6, 47));
  box-shadow: -2px -2px 4px 0 rgb(233, 145, 190), 2px 2px 4px 0 rgb(133, 45, 101),
    1px 1px 7px 0 rgb(235, 236, 240);
}
