/* ================= ABOUT ME ================= */

.skill-grid {
  margin: 0 auto;
  width: min(40em, 100%);
  display: flex;
  justify-content: space-around;
}
.skill-grid .skill-column {
  width: 100%;
  transform: translateX(-25%);
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.skill-grid .skill-column > * {
  width: min-content;
  margin: 0 0 0 auto;
}

@media (max-width: 720px) {
  .skill-grid {
    display: block;
    width: max-content;
    transform: translateX(-50%);
    margin-left: 50%;
  }
  .skill-grid .skill-column {
    width: 100% !important;
    margin-bottom: 4px;
    transform: unset;
  }
}

.portrait {
  margin-right: 40px;
  border-radius: 50%;
  width: 12em;
  box-shadow: var(--neu-shadow1);
}
body.dark .portrait {
  box-shadow: var(--neu-shadow-dark2);
}
.portrait-grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
}
@media (max-width: 720px) {
  .portrait-grid {
    display: block;
  }
  .portrait {
    margin: 0 50% 40px 50%;
    transform: translateX(-50%);
  }
}
