.contact-e {
  position: relative;
  cursor: pointer;
}
.contact-e svg {
  position: absolute;
  height: 0.98em;
  top: 0.3em;
  left: 5px;
}
@media (max-width: 720px) {
  .contact-e svg {
    top: 0;
    left: 0;
  }
  footer .contact-e svg {
    margin: 0 auto;
    position: relative;
  }
  footer p {
    width: 100%;
    text-align: center;
  }
  footer .footer-container {
    padding: 2.5em 1em !important;
  }
}
.contact-e path {
  fill: var(--black-text);
}
body.dark .contact-e path {
  fill: var(--white-text);
}
.contact-e:hover path,
footer a:hover,
body.dark .contact-e:hover path,
body.dark footer a:hover {
  fill: #22a3f0;
}
