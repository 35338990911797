/* ================= FOOTER ================= */

.footer {
  margin: 5em 0;
  width: 100%;
}
.footer .footer-container {
  padding: 2.5em 4em;
}
.footer p,
.footer span,
.footer a {
  color: var(--black-text);
  padding: 0;
  margin: 0;
  text-decoration: none;
}
body.dark .footer p,
body.dark .footer span,
body.dark .footer a {
  color: var(--white-text);
}
.footer a.link:hover,
body.dark .footer a.link:hover {
  color: #22a3f0;
}
.footer .copyright {
  width: 100%;
  margin-top: 1.5em;
}
.footer .copyright,
.footer .copyright *,
body.dark .footer .copyright,
body.dark .footer .copyright * {
  color: #66698366;
}
.contact-e {
  position: relative;
  cursor: pointer;
}
.contact-e svg {
  position: absolute;
  height: 0.98em;
  top: 0.3em;
  left: 5px;
}
@media (max-width: 720px) {
  .contact-e svg {
    top: 4px;
    left: 4px;
  }
  .footer .contact-e svg {
    margin: 0 auto;
    position: relative;
  }
  .footer p {
    width: 100%;
    text-align: center;
  }
  .footer .footer-container {
    padding: 2.5em 1em;
  }
}
.contact-e path {
  fill: var(--black-text);
}
body.dark .contact-e path {
  fill: var(--white-text);
}
.contact-e:hover path,
body.dark .contact-e:hover path {
  fill: #22a3f0;
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 720px) {
  .grid-2 {
    display: block;
  }
}
