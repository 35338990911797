.blog-entry {
  --width: min(100%, 60em);
  width: var(--width);
  height: max-content;
  padding: 0 !important;
  transition: transform 0.5s;
  transform: translateY(0px);
  overflow: hidden;
}
.blog-entry .blog-entry-details {
  padding: 0.5em 1.5em 1em;
}
.blog-skeleton {
  border: 1px solid #f5f5f5;
  box-shadow: 0 8px 32px 0 rgba(209, 209, 209, 0.37);
  border-radius: 15px;
}
.img-skeleton {
  transform: translateY(-5px);
}
