.blog-entry {
  --width: min(100%, 60em);
  width: var(--width);
  height: max-content;
  padding: 0 !important;
  transition: transform 0.5s;
  transform: translateY(0px);
}
.blog-entry .blog-link {
  text-decoration: none;
}
.blog-entry:hover {
  transition: transform 0.5s;
  transform: translateY(-10px);
}
.blog-entry .blog-entry-details {
  padding: 0.5em 1.5em 1em;
}
.blog-entry img {
  width: calc(var(--width) - 2 * 2px);
  aspect-ratio: 1 / 2;
  position: relative;
  object-fit: cover;
  margin: 0 2px;
  padding: 0;
  border-radius: 15px 15px 0 0;
  cursor: pointer;
  background-position: center;
  background-size: 75%;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 717 348"><rect fill="%2366698388" width="554" height="51" rx="25.5"/><rect fill="%2366698388" y="99" width="655" height="51" rx="25.5"/><rect fill="%2366698388" y="198" width="606.44" height="51" rx="25.5"/><rect fill="%2366698388" y="297" width="717" height="51" rx="25.5"/></svg>');
  box-shadow: 0 15px 20px -20px rgba(0, 0, 0, 0.2);
}
body.dark .blog-entry img {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 717 348"><rect fill="%23fff2" width="554" height="51" rx="25.5"/><rect fill="%23fff2" y="99" width="655" height="51" rx="25.5"/><rect fill="%23fff2" y="198" width="606.44" height="51" rx="25.5"/><rect fill="%23fff2" y="297" width="717" height="51" rx="25.5"/></svg>');
}
.blog-entry h2 {
  font-size: 1.25em;
  font-weight: 600;
  text-align: left;
}
.blog-entry .blog-link:hover h2 {
  color: var(--color-primary);
}
.blog-entry h2,
.blog-entry p {
  margin: 0;
}
