img.blog-img {
  width: 60%;
  border-radius: 15px;
  margin: 0 20% 1em 20%;
}
@media (max-width: 720px) {
  img.blog-img {
    width: 90%;
    margin: 0 5% 1em 5%;
  }
}
.date {
  width: 100%;
  margin: 0;
  text-align: center;
  font-style: italic;
}
