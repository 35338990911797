.header {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  align-content: center;
  margin: 0;
  padding: 0;
}
header .logo-hero {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.header .logo-hero .logo-cta {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 18em;
  height: 18em;
  border-radius: 75% 0 75% 0;
  cursor: pointer;

  opacity: 0.4;
}
.header .logo-hero img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: scale(0) translate(-50%, -50%);
  width: 16em;
  height: 16em;
  filter: drop-shadow(4px 3px 19px rgb(189, 193, 209));
  opacity: 1;
  animation: fade-in 0.5s ease-in forwards 2s;
}
.header .logo-hero img.logo-wings {
  filter: drop-shadow(4px 3px 19px rgb(189, 193, 209))
    drop-shadow(-7px -7px 16px rgb(250, 251, 252));
}
body.dark .header .logo-hero img.logo-wings {
  filter: drop-shadow(4px 3px 19px rgb(27, 28, 34)) drop-shadow(-7px -7px 16px rgb(61, 64, 71));
}
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(1) translate(-50%, -50%);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }
}
.header .logo-hero img.logo-gear {
  margin: 0;
  filter: drop-shadow(0.7px 0.7px 5px rgb(157, 161, 178));
  animation: fade-in 0.5s ease-in forwards 2s, logo-gear-fade-in 0.5s ease-in forwards 2s,
    spin 1.5s ease-out 2.5s;
}
body.dark .header .logo-gear {
  filter: drop-shadow(4px 3px 19px rgb(27, 28, 34));
}
@keyframes logo-gear-fade-in {
  0% {
    opacity: 0;
    transform: scale(1) translate(-50%, -50%);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }
}
@keyframes spin {
  0% {
    transform: scale(1) translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: scale(1) translate(-50%, -50%) rotate(360deg);
  }
}

.header .logo-outline {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 16.5em;
  height: 16.5em;
  animation: fade-out 0.5s ease-out forwards 2s;
}
.header .logo-outline .logo-segment {
  stroke: var(--black-text);
}
body.dark .header .logo-outline .logo-segment {
  stroke: var(--white-text);
}
@keyframes fade-out {
  to {
    opacity: 0;
  }
}
.header .logo-outline .logo-segment {
  fill: none;
  stroke-miterlimit: 10;
  stroke-width: 35px;
}
.header .logo-outline .out-draw-1 {
  stroke-dasharray: 1150;
  stroke-dashoffset: 1150;
  animation: draw 0.5s linear forwards;
}
.header .logo-outline .out-draw-2 {
  stroke-dasharray: 5450;
  stroke-dashoffset: 5450;
  animation: draw 1.5s linear forwards 0.5s;
}
.header .logo-outline .out-draw-3 {
  stroke-dasharray: 2650;
  stroke-dashoffset: 2650;
  animation: draw 0.5s linear forwards 1s;
}
.header .logo-outline .out-draw-4 {
  stroke-dasharray: 2650;
  stroke-dashoffset: 2650;
  animation: draw 0.5s linear forwards 1.25s;
}
@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@media (max-width: 720px) {
  .header .logo-outline {
    width: calc(16.5em * 0.75);
    height: calc(16.5em * 0.75);
  }
  .header .logo-hero img {
    width: calc(16em * 0.75);
    height: calc(16em * 0.75);
  }
  .header .logo-hero .logo-cta {
    width: calc(18em * 0.75);
    height: calc(18em * 0.75);
  }
}

/* Code Window */
.code-window {
  position: absolute !important;
  /*background: #ecedf1;*/
  width: max(25vw, 10em);
  height: calc(calc(max(25vw, 10em)) * 9 / 16);
  font-size: max(2.5vw, 1em);
  /*box-shadow: var(--neu-shadow2);*/
  padding: 0.8em 0 0 1.2em;
  border-radius: 0.45em;
  overflow: hidden;
  transform: scale(0);
  top: 10vw;
  left: 5vw;
  animation: code-window-popup 0.25s ease-in forwards 3s;
}
body.dark .code-window {
  background: #1e1e1e;
  box-shadow: var(--neu-shadow-dark2);
}
@keyframes code-window-popup {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.code-window .window-bar {
  display: flex;
  align-items: center;
  gap: 0.25em;
  position: absolute;
  margin: -0.8em 0 0 -1.2em;
  padding: 0 0.5em;
  height: 0.9em;
  width: 100%;
  /*background-color: rgba(183, 190, 239, 0.2);*/
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
}
body.dark .code-window .window-bar {
  background-color: #323233;
}
.code-window .window-bar p {
  width: 0.55em;
  height: 0.55em;
  position: relative;
  border-radius: 50%;
  /*border: solid 1px #2c2c2c55;*/
  box-shadow: 2px 2px 10px -4px rgba(0, 0, 0, 0.2);
  background: #c91f37;
  right: 0;
}
.code-window .window-bar p:nth-child(3) {
  background: #049372;
}
.code-window .window-bar p:nth-child(2) {
  background: #ffb61e;
}
.code-window .window-linenums::after {
  content: "1 2 3 4 5 6";
  position: absolute;
  margin: 0.25em 0 0 -1.25em;
  width: 1em;
  height: 100%;
  color: rgba(59, 75, 147, 0.27);
  font-family: "Consolas", sans-serif;
  font-size: 0.75em;
}
body.dark .code-window .window-linenums::after {
  color: #aaa;
}
.code-window p {
  font-family: "Consolas", sans-serif;
  font-size: 0.75em;
  padding: 0;
  margin: 0;
  /* color: white; */
}
@media (max-width: 920px) and (min-width: 768px) {
  .code-window {
    top: 15vw;
    left: 5vw;
  }
}
@media (max-width: 768px) {
  .code-window {
    --width: min(80vw, 10em);
    top: calc(100% - calc(var(--width) * 9 / 16) * 0.4);
    left: calc(50% - calc(var(--width) * 9 / 16));
    width: var(--width);
    height: calc(var(--width) * 9 / 16);
    font-size: min(8vw, 2em);
  }
  .header {
    margin-bottom: calc(calc(80vw) * 9 / 16);
  }
}

/* Typing animation */
.typewriter {
  display: grid;
  margin-top: 0.2em;
}
.typewriter p::before,
.typewriter p::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.typewriter p {
  position: relative;
  width: max-content;
}
.typewriter p {
  overflow: hidden;
}
.typewriter p::after {
  width: 0.1em;
  background: var(--black-text);
  opacity: 0;
}
body.dark .typewriter p::after {
  background: var(--white-text);
}
.typewriter .ln {
  position: relative;
  overflow: hidden;
  width: 0;
  padding-right: 0.075em;
}
.typewriter .ln::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0.1em;
  height: 100%;
  background: var(--black-text);
}
.typewriter .ln1 {
  --steps: 20;
  animation: typewriter 3s steps(var(--steps)) forwards 3s;
}
.typewriter .ln.ln1::after {
  animation: show-blink 1ms forwards 3s, blink 750ms steps(var(--steps)) infinite,
    hide-blink 1ms forwards 6s;
}
.typewriter .ln2 {
  --steps: 22;
  animation: typewriter 3s steps(var(--steps)) forwards 6s;
}
.typewriter .ln.ln2::after {
  animation: init-blink 9s forwards, show-blink 1ms forwards 6s,
    blink 750ms steps(var(--steps)) infinite, hide-blink 1ms forwards 9s;
}
.typewriter .ln3 {
  --steps: 8;
  animation: typewriter 1.5s steps(var(--steps)) forwards 9s;
}
.typewriter .ln.ln3::after {
  animation: init-blink 9s forwards, show-blink 1ms forwards 9s,
    blink 750ms steps(var(--steps)) infinite, hide-blink 1ms forwards 10.5s;
}

@keyframes typewriter {
  to {
    width: 100%;
  }
}
@keyframes blink {
  to {
    background: #f1f2f8;
  }
}
@keyframes show-blink {
  to {
    opacity: 1;
    background: var(--black-text);
  }
}
@keyframes hide-blink {
  to {
    opacity: 0;
  }
}
@keyframes init-blink {
  from,
  to {
    background: #f1f2f8;
  }
}
