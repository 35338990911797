.blog-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
}
@media (max-width: 1024px) and (min-width: 720px) {
  .blog-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .blog-grid.preview div:nth-child(3n) {
    display: none;
  }
}
@media (max-width: 720px) {
  .blog-grid {
    grid-template-columns: 1fr;
  }
  .blog-grid.preview div:nth-child(3n) {
    display: none;
  }
}
