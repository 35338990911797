.contact {
  margin: 0 auto;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contact input {
  width: 100%;
}
.contact .input-row-2 {
  display: flex;
  gap: 10px;
}
.contact .submit {
  width: 150px;
  float: right;
}
.contact .recaptcha {
  /* border: none; */
  border-radius: 15px;
  width: 100%;
  height: 50px;
}
.contact .captcha-boundingbox {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0;
}
.contact button.refresh svg {
  width: 100%;
  height: 100%;
}
body.dark .contact button.refresh svg {
  fill: var(--white-text);
}
.contact button.refresh {
  padding: 14px 14px;
}
.contact .error {
  box-shadow: 0 0 0 2px #f00 !important;
  color: #f00 !important;
}
.contact .text-container textarea {
  width: 100%;
}
.contact .text-container {
  display: flex;
  position: relative;
}
.contact .char-display {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #b2b2b2;
}

@media (max-width: 720px) {
  .contact .input-row-2 {
    flex-direction: column;
  }
  .contact input {
    width: auto;
  }
}
